<template>
  <div style="padding-bottom: 200px">
    <form
      @submit.prevent="submitHandler"
      @keyup.enter="handleKeyPress"
    >
      <div class="formgrid p-fluid grid">
        <div class="field col-12 sm:col-6">
          <div :class="jobTitleClass()">
            <label
              style="color: #687a8d !important"
              for="name"
            >Job Name</label>
            <Button
              v-if="hideTemplateButton == false"
              label="From template"
              class="p-button-text p-button-primary w-auto p-2"
              icon="las la-plus"
              @click="toggle"
            />
            <OverlayPanel
              ref="op"
              appendTo="body"
              :showCloseIcon="true"
              id="overlay_panel"
              style="width: 450px"
            >
              <DataTable
                :value="jobs_template"
                :selection.sync="job_template"
                selectionMode="single"
                :paginator="true"
                :rows="5"
                @row-select="handleTemplate(job_template)"
              >
                <template #empty> Nothing yet... </template>
                <Column
                  field="title"
                  header="Title"
                  sortable
                ></Column>
              </DataTable>
            </OverlayPanel>
          </div>
          <div class="p-inputgroup">
            <InputText
              v-model="job.title"
              placeHolder="Job Name"
            />
          </div>

          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.job.title.required"
          >
            Job name is required
          </div>
        </div>
        <!-- <div class="field col-12 sm:col-6">
          <label for="name">Status</label>
          <div v-if="job.uid" class="p-inputgroup">
            <Dropdown
              :options="jobsStatus"
              optionLabel="name"
              optionValue="uid"
              v-model="job.status"
              placeholder="Select Status"
              class="input"
            />
          </div>
          <div v-if="!job.uid" class="p-inputgroup">
            <Dropdown
              :options="jobsStatus"
              optionLabel="name"
              optionValue="uid"
              v-model="job.status"
              placeholder="Select Status"
              class="input"
              disabled
            />
          </div>
        </div> -->
        <div
          class="field col-12 sm:col-6"
          :style="hideTemplateButton !== false ? '' : 'padding-top: 0.5rem'"
        >
          <div class="pt-2 pb-2">
            <label style="color: #687a8d">Category</label>
          </div>
          <div class="p-inputgroup">
            <Dropdown
              id="ae"
              :options="categories"
              optionLabel="description"
              optionValue="uid"
              v-model="job.category"
              dataKey="uid"
              placeholder="Select Categories"
              class="input"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.job.category.required"
          >
            Category is required
          </div>
        </div>
        <template v-if="!quickClient">
          <div class="field col-12 sm:col-6">
            <div class="flex align-items-center justify-content-between">
              <label for="name">Client</label>
              <Button
                label="Add Quick Client"
                class="p-button-text p-button-primary w-auto p-2"
                icon="las la-plus"
                @click="quickClient = true"
              />
            </div>

            <Dropdown
              :options="clients"
              dataKey="uid"
              optionLabel="name"
              :filter="true"
              optionValue="uid"
              @change="handleContacts(client)"
              v-model="client"
              placeholder="Select Client"
              class="input"
            />

            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.client.required"
            >
              Client is required
            </div>
          </div>

          <div
            class="field col-12 sm:col-6"
            style="padding-top: 0.5rem"
          >
            <label for="name">Contact</label>
            <div
              class="p-inputgroup"
              v-if="contacts.length > 0"
            >
              <Dropdown
                :options="contacts"
                optionLabel="name"
                optionValue="uid"
                v-model="contact"
                placeholder="Select Contact"
                class="input"
              >
                <template #option="slotProps">
                  <span>{{
                    slotProps.option.name +
                    (slotProps.option?.surname
                      ? " " + slotProps.option?.surname
                      : "")
                  }}</span>
                </template>
              </Dropdown>
            </div>
            <div
              v-else
              class="p-inputgroup"
            >
              <Dropdown
                disabled
                placeholder="No contacts assigned yet"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-6 pb-2 mt-2">Add Quick Client</div>
          <div
            class="col-6 pb-2 mt-2 text-end cursor-pointer"
            @click="quickClient = false"
          >
            Cancel
          </div>
        </template>
        <div
          class="formgrid p-fluid grid p-2 pt-3 mb-3"
          style="background-color: #e9ecef; border-radius: 6px"
          v-if="quickClient"
        >
          <div class="field col-12 sm:col-6">
            <label for="name">Company</label>
            <div class="p-inputgroup">
              <InputText
                placeHolder="Company"
                v-model="newCompanyName"
              />
            </div>
          </div>

          <div class="field col-12 sm:col-6">
            <label for="name">Billing Tier</label>

            <div class="p-inputgroup">
              <Dropdown
                :options="billingTiers"
                optionLabel="name"
                optionValue="uid"
                v-model="newBillingTier"
                placeholder="Select Billing Tier"
                class="input"
              >
              </Dropdown>
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="name">Contact Name</label>
            <div class="p-inputgroup">
              <InputText
                placeHolder="Contact Name"
                v-model="newContact"
              />
            </div>

            <div
              class="error-validation-text pt-1"
              v-if="quickClient && submitted && !$v.newContact.required"
            >
              Please enter a valid contact
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="name">Email</label>
            <div class="p-inputgroup">
              <InputText
                placeHolder="Company"
                v-model="newEmail"
              />
            </div>

            <div
              class="error-validation-text pt-1"
              v-if="quickClient && submitted && !$v.newEmail.email"
            >
              Please enter a valid email
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="name">Contact Number</label>
            <div class="p-inputgroup">
              <InputText
                placeHolder="Contact Number"
                v-model="newContactNo"
              />
            </div>

            <div
              class="error-validation-text pt-1"
              v-if="quickClient && submitted && !$v.newContactNo.required"
            >
              Please enter a valid contact number
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="name">Tax</label>
            <div class="p-inputgroup flex align-items-center">
              <div
                for="tax"
                class="mr-2 pl-1"
              >Add Tax/Vat</div>
              <Checkbox
                id="tax"
                v-model="hasTax"
                :binary="true"
              />
            </div>
          </div>
        </div>

        <div class="field col-12 sm:col-6">
          <label class="label">Start Date</label>
          <div>
            <Calendar
              id="icon"
              autocomplete="off"
              v-model="job.start_date"
              :showIcon="true"
              :selectOtherMonths="true"
              :showOtherMonths="true"
              dateFormat="yy-mm-dd"
              placeholder="Start Date"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.job.start_date.required"
          >
            Start date is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label class="label">Due Date</label>
          <div>
            <Calendar
              id="icon"
              autocomplete="off"
              v-model="job.due_date"
              :showIcon="true"
              :selectOtherMonths="true"
              :showOtherMonths="true"
              dateFormat="yy-mm-dd"
              placeholder="Due Date"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.job.due_date.required"
          >
            Due date is required
          </div>
        </div>

        <!-- <div class="col-12 sm:col-6 field-checkbox">
          <div
            :class="
              'flex align-items-center' +
              (job_template ? ' cursor-disable' : '')
            "
          >
            <Checkbox
              id="binary"
              :binary="true"
              @change="!!job_template ? handleTemplate(job_template.uid) : ''"
              :disabled="job_template !== null"
              v-model="is_template"
            />
            <label for="binary" class="font-light cursor-pointer ml-1"
              >Template</label
            >
          </div>
        </div> -->
        <div class="field col-12">
          <label for="name">Description</label>
          <div>
            <Editor
              ref="editor"
              placeholder="Description"
              id="Description"
              :autoResize="true"
              v-model="job.description"
              editorStyle="height:150px"
            >
              <template #toolbar>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button><button class="ql-strike"></button>
                  <button
                    class="ql-list"
                    value="ordered"
                  ></button>
                  <button
                    class="ql-list"
                    value="bullet"
                  ></button>
                  <button class="ql-align"></button>
                  <button class="ql-link"></button>
                </span> </template>
            </Editor>
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.job.description.required"
          >
            Description is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Account Executive</label>
          <span class="p-input-icon-left">
            <Dropdown
              id="ae"
              :options="usersData.users"
              :filter="true"
              optionLabel="name"
              optionValue="uid"
              v-model="managed_by"
              placeholder="Select Account Executive"
              class="input"
            />
          </span>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.managed_by.required"
          >
            Account Executive is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Budget</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">{{ currency }}</span>
            <InputNumber
              :minFractionDigits="2"
              v-model="job.budget_amount"
              locale="en-US"
              placeHolder="Budget"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-content-between pt-5">
        <div>
          <Button type="submit"> {{ job?.copy ? "COPY" : "SAVE" }}</Button>
        </div>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>
  </div>
</template>
<script>
import { fetchBillingTiers, state } from "../../../services/data_service.js";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "JobForm",
  props: {
    jobProp: {
      type: Object,
    },
    isNew: {
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {
      newCompanyName: null,
      newEmail: null,
      newBillingTier: null,
      hasTax: false,
      newContactNo: null,
      newContact: null,
      quickClient: false,
      hideTemplateButton: false,
      submitted: false,
      selectedDepartments: [],
      managed_by: null,
      formOptions: [],
      myFiles: [],
      name: "",
      contacts: [],
      currency: null,
      job_template: null,
      client: {
        name: null,
        billingTier: null,
        contact: null,
        email: null,
      },
      contact: null,
      checked: true,
      isLoading: null,
      loadingText: "Loading...",
      inputIsVisible: false,
      job: {
        managed_by: null,
        budget_amount: null,
        title: null,
        uid: null,
        due_date: null,
        start_date: null,
        status: null,
        description: null,
      },
    };
  },
  validations: {
    job: {
      title: {
        required,
      },
      category: {
        required,
      },
      start_date: {
        required,
      },
      due_date: {
        required,
      },
      description: {
        required,
      },
    },
    client: {
      required,
    },
    managed_by: {
      required,
    },
    newEmail: {
      email,
    },
    newContact: {
      required,
    },
    newContactNo: {
      required,
    },
  },
  mounted() {
    fetchBillingTiers();
    if (this.jobProp === null) {
      this.job.start_date = this.getTodayDate();
    }
    if (this.jobProp) {
      this.jobProp.due_date = this.$moment(this.jobProp.due_date).format(
        "YYYY-MM-DD"
      );
      this.jobProp.start_date = this.$moment(this.jobProp.start_date).format(
        "YYYY-MM-DD"
      );
      if (this.job.is_template === 1) {
        this.handleTemplate(this.jobProp);
      } else if (this.jobProp.job_from_template === 1) {
        this.handleTemplate(this.jobProp);
      } else {
        this.client = this.jobProp.client.uid;
        this.contact = this.jobProp.client.contact;
        this.managed_by = this.jobProp.managed_by.uid;
        this.handleContacts(this.client);
        this.job = JSON.parse(JSON.stringify(this.jobProp));
        this.job.start_date = this.jobProp.date_start?.date;
      }
      if (this.jobProp.job_from_template !== null) {
        if (this.jobProp.job_from_template == 1) {
          this.hideTemplateButton = true;
        } else if (this.isNew == true) {
          this.hideTemplateButton = false;
        } else {
          this.hideTemplateButton = true;
        }
      }
    } else {
      if (this.job.status == null) {
        this.job.status = this.jobsStatus?.find(
          (job) => job.name === "Planned"
        ).uid;
      }
    }

    if (this.job.copy) {
      this.job.start_date = this.getTodayDate();
      this.job.due_date = null;
    }
    // this.clients.sort((a, b) => a.name.localeCompare(b.name))
  },
  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    getTodayDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    jobTitleClass() {
      if (this.hideTemplateButton == true) {
        return "flex align-items-center justify-content-between mb-2 pt-2";
      }
      return "flex align-items-center justify-content-between pt-2";
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },

    handleTemplate(obj) {
      this.jobs_template.map((template) => {
        if (template.uid === obj.uid) {
          this.job.title = template.title;
          this.job.is_template = 1;
          this.job.category = template.category_uid;
          this.job.description = template.description;
          // this.job.title = template.title;
          // this.job.budget_amount = template.budget.amount;
          // this.job.due_date = template.date_due.date;
          // this.job.start_date =
          //   template.start_date == undefined
          //     ? new Date("2022-01-03")
          //     : template.start_date;
          // this.client = template.client.uid;
          this.job.status = template.status_uid;
          // this.managed_by = template.managed_by.uid;
          // this.contact = template.client.contact;
          // this.handleContacts(this.client);
        }
        return template;
      });
    },
    copyJob() {
      var form = new FormData();
      form.append("title", this.job.title);
      form.append("client", this.client);
      form.append("budget_amount", this.job.budget_amount);
      form.append("managed_by", this.managed_by);
      form.append("description", this.job.description);
      if (this.quickClient) {
        form.append("client_name", this.newCompanyName);
        form.append("billing_tier", this.newBillingTier);
        form.append("contact", this.newContact);
        form.append("contactNumber", this.newContactNo);
        form.append("email", this.newEmail);
      }
      form.append(
        "date_due",
        this.$moment(this.job.due_date).format("YYYY-MM-DD")
      );
      form.append(
        "date_start",
        this.$moment(this.job.start_date).format("YYYY-MM-DD")
      );
      form.append("category", this.job.category);
      form.append("status", this.job.status);
      form.append("contact", this.contact);
      var url = "/v1/jobs/" + this.job.uid + "/clone";
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + url, form, {
          headers: { "Content-type": "application/json" },
        })
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Job Copied",
            life: 3000,
          });
          const uid = response.data.data;

          this.$store.dispatch("setSliderLoader", false);
          if (uid) {
            this.$store.dispatch("setSliderLoader", false);
            this.clearSliderContent();
            this.$router.push("/job/j" + uid);
            this.$bus.emit("refreshData");
          } else {
            this.clearSliderContent();
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
        });
    },
    navigateToAddClient() {
      this.$router.push("/client");
      this.clearSliderContent();
    },
    submitHandler() {
      this.submitted = true;
      if (this.quickClient) {
        this.$v.newContact.$touch();
        this.$v.newEmail.$touch();

        if (this.$v.newContact.$invalid || this.$v.newEmail.$invalid) {
          this.$toast.add({
            severity: "error",
            summary: "Please complete all the required fields for Quick Client",
            life: 3000,
          });
          return;
        }
      } else {
        this.$v.job.$touch();
        this.$v.client.$touch();
        this.$v.managed_by.$touch();

        if (
          this.$v.job.$invalid ||
          this.$v.client.$invalid ||
          this.$v.managed_by.$invalid
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Please complete all the required fields",
            life: 3000,
          });
          return;
        }
      }

      if (this.job.copy) {
        this.copyJob();
        return;
      } else {
        this.$store.dispatch("setSliderLoader", true);
        var url = "/v1/jobs";
        if (this.obj && !this.isNew) {
          url = url + "/" + this.obj.uid;
        }

        this.loadingText = "Processing...";
        var form = new FormData();
        form.append("title", this.job.title);

        if (!this.quickClient) {
          form.append("client", this.client);
        } else {
          form.append("client_name", this.newCompanyName);
          form.append("billing_tier", this.newBillingTier);
          form.append("contact", this.newContact);
          form.append("contactNumber", this.newContactNo);
          form.append("tax", this.hasTax);
          form.append("email", this.newEmail);
        }
        form.append(
          "date_due",
          this.$moment(this.job.due_date).format("YYYY-MM-DD")
        );
        if (this?.job?.budget_amount) {
          form.append("budget_amount", this.job.budget_amount);
        }
        form.append("managed_by", this.managed_by);
        form.append("category", this.job.category);
        if (this.contact) {
          form.append("contact", this.contact);
        }
        form.append(
          "date_start",
          this.$moment(this.job.start_date).format("YYYY-MM-DD")
        );
        form.append("description", this.job.description);
        if (this.job_template !== null) {
          form.append("template", this.job_template.uid);
        }

        this.$axios
          .post(process.env.VUE_APP_ROOT_API + url, form, {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          })
          .then((response) => {
            const uid = response.data.data.uid;
            this.$store.dispatch("setSliderLoader", false);
            if (!this.job.uid) {
              this.$store.dispatch("setSliderLoader", false);
              this.clearSliderContent();
              this.$router.push("/job/" + uid);
            } else {
              this.clearSliderContent();
            }
          })
          .catch((error) => {
            this.$store.dispatch("setSliderLoader", false);
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
    handleContacts(id) {
      if (!this.contact) {
        var client = this.clients.find((client) => client.uid === id);
        var primaryClient = client.contacts.find(
          (contact) => contact.is_primary === 1
        );
        if (primaryClient) {
          this.contact = primaryClient.uid;
        } else {
          this.contact = client.contacts[0].uid;
        }
      }

      this.clients.forEach((client) => {
        if (client.uid === id) {
          if (!this.isNew) {
            if (this.obj && this.obj.client.uid !== id) {
              this.$toast.add({
                severity: "warn",
                summary:
                  "New client has different currency and will require budget update.",
                life: 3000,
              });
            }
          }

          this.contacts = client.contacts;

          // this.contacts.forEach((c) => {
          //   c.contact_name =
          //     c.contactDetails.name + " " + c.contactDetails.surname;
          // });

          this.currency = client.currency.name.substring(0, 4);
        }
      });
    },
  },
  computed: {
    billingTiers() {
      return state.billingTiers;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
    usersData() {
      return state.usersData;
    },
    stateJob() {
      return state.job;
    },
    categories() {
      return state.categories;
    },
    jobs_template() {
      return state.jobs.filter((job) => job.is_template == 1);
    },
    jobsStatus() {
      return state.jobsStatus;
    },
    clients() {
      return state.clients
        ?.filter((client) => client.active == 1)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {},
};
</script>