<template>
  <div>
    <RocketLoad v-if="isLoading" :full="true" />

    <div v-else>
      <div class="formgrid grid flex align-items-center pb-3">
        <div class="field col-12 align-items-center pl-0">
          <div class="flex align-items-center">
            <h2 class="mb-0">
              <template v-if="obj == null || obj.job_from_template === 1">
                Create New Job
              </template>
              <template v-else>
                <span v-if="!obj.copy">Edit</span><span v-else>Copy</span> Job
              </template>
            </h2>

            <span class="text-12 pl-4" v-if="obj">
              <span
                v-if="job?.accepted === 1"
                :style="getTagStyle('Accepted')"
                class="p-2"
                >Accepted</span
              >
              <span
                v-else-if="job?.status"
                :style="getTagStyle('Accepted') + ' borderRadius: 6px'"
                class="p-2"
                >{{ job?.status }}</span
              >
            </span>
          </div>
        </div>

        <JobForm
          :jobProp="obj"
          :isNew="obj == null || obj.job_from_template === 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  state,
  fetchUsersData,
  fetchJob,
  fetchJobsStatus,
  fetchClients,
  fetchCategories,
} from "../../../services/data_service.js";
import JobForm from "../../Job/components/JobForm.vue";
export default {
  name: "UserSliderDetails",
  components: {
    JobForm,
  },
  data() {
    return {
      index: 0,
      status: null,
    };
  },
  created() {
    this.$store.dispatch("setSliderLoader", true);
  },
  methods: {
    async loadData() {
      if (this.obj) {
        await Promise.all([
          fetchUsersData(),
          fetchCategories(),
          fetchJobsStatus(),
          fetchClients(),
          fetchJob(this.obj.uid),
        ]);
      } else {
        await Promise.all([
          fetchUsersData(),
          fetchCategories(),
          fetchJobsStatus(),
          fetchClients(),
        ]);
      }
    },
    handleContacts(id) {
      this.clients.map((client) => {
        if (client.uid === id) {
          this.contacts = client.contacts;
          this.currency = client.currency.name.substring(0, 4);
        }
        return client;
      });
    },
    getTagStyle(status) {
      if (this.status === "Overdue") {
        return "background: #FFF1F7 !important;color: #FE4D97 !important;";
      } else if (this.status === "Overdue") {
        return "background: #f4fbef !important;color: #FFAB2B !important;";
      }
      return "background: #f4fbef !important;color: #6dd230 !important;";
    },
  },
  async mounted() {
    await this.loadData();
    if (this.obj) {
      this.job = this.obj;
      this.job.due_date = this.job.date_due.date;
      this.job.budget_amount = this.job.budget.amount;
      this.managed_by = this.job.managed_by.uid;
      this.client = this.job.client.uid;
      this.job.start_date =
        this.job.start_date == undefined
          ? new Date("2022-01-03")
          : this.job.start_date;
      this.job.category = this.job.category_uid;
      this.managed_by = this.job.managed_by.uid;
      this.job.status = this.job.status_uid;
      this.contact = this.job.client.contact;
      this.handleContacts(this.job.client.uid);
    } else {
      this.status = this.jobsStatus.find((job) => job.name === "Planned").uid;
    }
    this.$store.dispatch("setSliderLoader", false);
  },
  computed: {
    isLoading() {
      return this.$store.getters.sliderLoader;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
    clients() {
      return state.clients;
    },
    jobsStatus() {
      return state.jobsStatus;
    },
  },
};
</script>